.container-clients {
	height: auto;
	min-height: 60dvh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.title-clients {
	width: 100%;
	text-align: center;
	border-bottom: 5px solid #000;
	border-color: var(--secondary);
	line-height: 0.1em;
	font-size: 3rem;
	font-weight: 900;
	margin-top: 100px;
}

.title-clients span {
	background: #fff;
	padding: 0 10px;
}

/* SCROLLER */
.scroller {
	max-width: 100%;
}

.scroller__inner {
	padding-block: 1rem;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
}

.scroller[data-animated="true"] {
	overflow: hidden;
	-webkit-mask: linear-gradient(
		90deg,
		transparent,
		#000 20%,
		#000 80%,
		transparent
	);
	mask: linear-gradient(90deg, transparent, #000 20%, #000 80%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
	width: max-content;
	flex-wrap: nowrap;
	animation: scroll var(--_animation-duration, 40s)
		var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
	--_animation-direction: reverse;
}

.scroller[data-direction="left"] {
	--_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
	--_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
	--_animation-duration: 60s;
}

.tag {
	width: 200px;
	margin: 20px;
	align-self: center;
}

@keyframes scroll {
	to {
		transform: translate(calc(-50% - 0.5rem));
	}
}

@media (max-width: 768px) {
	.title-clients {
		font-size: 2rem;
	}
}
