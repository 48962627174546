.container {
	height: 100dvh;
	width: 100%;
	background-color: var(--secondary);
	border-bottom: 1px solid #fff;
	animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-up both;
}

.picture-banner {
	width: 100%;
	height: 100%;
	object-fit: contain;
	padding-top: 20px;
}

.logo-container {
	position: absolute;
	left: 30px;
	top: 30px;
	width: 100px;
}

.item-arrow {
	position: absolute;
	width: 55px;
	height: 55px;
	left: 40px;
	bottom: 0;
	background-color: var(--secondary-light);
	border-radius: 16px;
	padding: 2px;
}

.back-arrow {
	width: 100%;
	height: auto;
	border-radius: 24px;
}

@media (max-width: 768px) {
	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: auto;
		min-height: 30vh;
	}

	.picture-banner {
		height: auto;
	}

	.item-arrow {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 20px;
		bottom: -90vh;
		background-color: var(--secondary-light);
		border-radius: 16px;
		padding: 2px;
	}

	.logo-container {
		position: absolute;
		left: 20px;
		top: 20px;
		width: 30px;
	}
}

@keyframes wipe-in-up {
	from {
		clip-path: inset(100% 0 0 0);
	}
	to {
		clip-path: inset(0 0 0 0);
	}
}
