.container-form {
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 100vh;
	height: auto;
	position: relative;
	font-size: 1rem;
	padding-inline: 5vw;
	margin-bottom: 20px;
	margin-top: 5px;
}

.header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 35vh;
	background-color: var(--secondary);
	padding: 40px 5vw;
	box-sizing: border-box;
}

.title-header {
	color: #fff !important;
	text-align: left !important;
	margin: 0;
	font-size: 4vw !important;
}

.description-header {
	width: 50%;
	font-size: 1.5vw;
	font-weight: 300;
}

.form {
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: #165959;
	color: #000;
	border: 1px solid #333;
	padding: 40px;
	gap: 10px 10px;
	border-radius: 20px;
}

.title-form {
	color: #80ffff;
}

.message,
.signin {
	font-size: 14.5px;
	text-align: left;
	color: #fff;
}

.signin a:hover {
	text-decoration: underline #80ffff;
}

.signin a {
	color: #80ffff;
}

.flex-input {
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 10px;
}

.form label {
	position: relative;
	width: 100%;
}

.input {
	background-color: #fff;
	color: #000;
	width: 100%;
	padding: 20px 05px 05px 10px;
	outline: 0;
	border: 1px solid rgba(105, 105, 105, 0.397);
	border-radius: 10px;
	box-sizing: border-box;
}

.form label .input + span {
	color: #000;
	position: absolute;
	left: 10px;
	top: 0px;
	font-size: 0.9em;
	cursor: text;
	transition: 0.3s ease;
}

.form label .input:placeholder-shown + span {
	top: 12.5px;
	font-size: 0.9em;
}

.form label .input:focus + span,
.form label .input:valid + span {
	color: #000;
	top: 0px;
	font-size: 0.7em;
	font-weight: 600;
}

.text-area {
	height: 200px;
}

.submit {
	border: none;
	outline: none;
	padding: 10px;
	border-radius: 10px;
	color: #000;
	font-size: 1rem;
	transition: transform 0.3s ease;
	width: 100%;
	background-color: #80ffff;
	align-self: flex-end;
	margin-right: -20px;
}

.submit:hover {
	background-color: #93d6d6;
	cursor: pointer;
}

.error {
	color: red;
	font-size: 0.98rem;
	margin: 0;
}

.form-inner {
	width: auto;
	min-width: 35dvw;
}

.btn {
	width: 100%;
}

@keyframes pulse {
	from {
		transform: scale(0.5);
		opacity: 0;
	}

	to {
		transform: scale(1.8);
		opacity: 1;
	}
}

@media (max-width: 768px) {
	.container-form {
		flex-direction: column;
		padding-inline: 0px;
	}

	.form-inner {
		display: flex;
		justify-content: center;
		position: relative;
		width: 95%;
		margin-top: 5vh;
	}

	.form {
		width: 100%;
		padding-inline: 10px;
	}

	.title-header {
		color: #fff !important;
		text-align: left !important;
		margin: 0;
		font-size: 10vw !important;
	}

	.description-header {
		width: 100%;
		font-size: 3.5vw;
		font-weight: 400;
		text-align: left;
	}

	.header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 45vh;
		background-color: var(--secondary);
		padding: 40px 5vw;
		box-sizing: border-box;
	}

	.flex-input {
		flex-direction: column;
	}

	.input {
		width: 100%;
	}
}
