.nav-container {
	z-index: 1000;
	width: 100dvw;
	position: fixed;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
}

.navbar {
	background-color: black;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
	padding: 15px;
	width: auto;
	font-size: 1.3vw;
	border-radius: 10px;
}

.items-nav {
	display: flex;
	justify-content: space-around;
}

.item-nav {
	transition: transform 0.3s ease;
}

.item-nav:hover {
	transform: scale(1.1);
}

.link-nav {
	text-decoration: none;
	color: white;
	margin-inline: 20px;
}

.link-nav:hover {
	color: var(--secondary-light);
}

.active {
	color: var(--secondary-light);
	border: 1px solid var(--secondary-light);
	padding: 8px;
}

@keyframes fade {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@media (max-width: 768px) {
	.nav-container {
		top: 20px !important;
		bottom: auto !important;
	}
	.navbar {
		padding: 10px;
		width: auto;
		font-size: 2.5vw;
		border-radius: 10px;
	}

	.link-nav {
		margin-inline: 5px;
	}
}
