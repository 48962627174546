.swiper {
	width: 25vw;
	height: auto;
}

.swiper-slide {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 18px;
	font-size: 22px;
	font-weight: bold;
	color: #fff;
	object-fit: cover;
}

.swiper-slide:nth-child(1n) {
	background-color: #ac2839;
}

.swiper-slide:nth-child(2n) {
	background-color: #201e1f;
}

.swiper-slide:nth-child(3n) {
	background-color: #fff;
}

.swiper-slide:nth-child(4n) {
	background-color: #e9d6c5;
}

@media (max-width: 767px) {
	.swiper {
		width: 80%;
		min-height: 50vh;
	}

	.shape {
		display: none;
	}
}

@media (min-width: 768px) and (max-width: 1024px) {
	.swiper {
		width: 80%;
		min-height: 70vh;
	}

	.shape {
		display: none;
	}
}
