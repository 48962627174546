.footer {
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: auto;
	background-color: var(--primary);
	width: 100%;
	text-align: center;
	padding-top: 50px;
	padding-bottom: 90px;
}

.copyright {
	color: white;
}

.link-ref {
	color: var(--secondary);
}

.logo {
	width: 100%;
	height: auto;
}

.logoContainer {
	width: 150px;
	align-self: center;
}

.link-container {
	display: flex;
	justify-content: center;
	gap: 10px;
}

.link {
	color: white;
	text-decoration: none;
}
