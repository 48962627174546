.card {
	width: fit-content;
	height: fit-content;
	align-self: center;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 25px 25px;
	gap: 20px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.055);
}

/* for all social containers*/
.socialContainer {
	width: 52px;
	height: 52px;
	background-color: rgb(44, 44, 44);
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	transition-duration: 0.3s;
}
/* instagram*/
.containerOne:hover {
	background-color: #d62976;
	transition-duration: 0.3s;
}
/* twitter*/
.containerTwo:hover {
	background-color: #0b66c2;
	transition-duration: 0.3s;
}
/* linkdin*/
.containerThree:hover {
	background-color: #000;
	transition-duration: 0.3s;
}
/* Whatsapp*/
.containerFour:hover {
	background-color: #128c7e;
	transition-duration: 0.3s;
}

.socialContainer:active {
	transform: scale(0.9);
	transition-duration: 0.3s;
}

.socialSvg {
	width: 17px;
}

.socialSvg path {
	fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvg {
	animation: slide-in-top 0.3s both;
}

@keyframes slide-in-top {
	0% {
		transform: translateY(-50px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
