.container-proyects {
	position: relative;
	min-height: 100dvh;
	height: auto;
	background-color: var(--secondary);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1002%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(0%2c 152%2c 152%2c 1)'%3e%3c/rect%3e%3cpath d='M1440 0L1118.41 0L1440 272.07z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M1118.41 0L1440 272.07L1440 280.58L1065.46 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M1065.46 0L1440 280.58L1440 415.27L985.61 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M985.61 0L1440 415.27L1440 479.58L625.37 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 560L588.14 560L0 376.81z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 376.81L588.14 560L650.55 560L0 359.78z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 359.78L650.55 560L987.52 560L0 263.91999999999996z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 263.92L987.52 560L1078.92 560L0 161.92000000000002z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1002'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
	background-size: cover;
}

.main-container {
	display: flex;
	width: 60%;
	height: auto;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.textContainer {
	margin-left: 5vw;
	margin-top: 24vh;
	box-sizing: border-box;
}

.cards {
	position: absolute;
	bottom: 200px;
	right: 100px;
}

.shape {
	position: absolute;
	bottom: -10vh;
	right: -3vw;
	width: 30vw;
	height: 70vh;
	background-color: #165959;
	border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
}

.title {
	font-size: 4vw;
	/* color: var(--primary); */
	color: #165959;
	font-weight: 900;

	margin-bottom: 50px;
}

.subtitle {
	font-size: 2.5vw;
	text-transform: uppercase;
	color: #ffea80;
	font-weight: 400;
}

.description-proyect {
	font-size: 1.8vw;
	color: white;
	font-weight: 400;
	letter-spacing: 1px;
}

.section {
	position: absolute;
	top: 80px;
	left: -50px;
	font-size: 1rem;
	background-color: var(--orange);
	width: auto;
	transform: rotateZ(-50deg);
	padding-inline: 80px;
	padding: 20px 120px;
	box-shadow: 0px 0px 0px 5px var(--primary);
	clip-path: polygon(
		0 0,
		100% 0,
		67% 53%,
		100% 100%,
		80% 100%,
		20% 100%,
		0 100%,
		39% 53%
	);
}

.container-proyects span {
	position: absolute;
	overflow: hidden;
	width: 200px;
	height: 200px;
	top: -20px;
	left: -20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.container-proyects span::before {
	content: "Proyectos";
	position: absolute;
	width: 150%;
	height: 40px;
	background-image: linear-gradient(
		45deg,
		#165959 0%,
		#165959 51%,
		#165959 100%
	);
	transform: rotate(-45deg) translateY(-20px);
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: 600;
	font-size: 1.2rem;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
}

.container-proyects span::after {
	content: "";
	position: absolute;
	width: 20px;
	top: 0px;
	right: 0;
	height: 20px;
	z-index: -1;
	box-shadow: 140px -140px #165959;
	background-image: linear-gradient(
		45deg,
		#165959 0%,
		#165959 51%,
		#165959 100%
	);
}

@media (max-width: 768px) {
	.container-proyects {
		display: flex;
		flex-direction: column;
		height: auto;
		min-height: 100vh;
	}

	.main-container {
		width: 100%;
	}

	.cards {
		position: relative;
		bottom: 0;
		right: 0;
		width: 100%;
		height: auto;
		margin-block: 20px;
	}

	.title {
		font-size: 10vw;
		/* color: var(--primary); */
		color: #165959;
		font-weight: 900;

		margin-bottom: 50px;
	}

	.subtitle {
		font-size: 6vw;
		text-transform: uppercase;
		color: #ffea80;
	}

	.description-proyect {
		font-size: 4vw;
		color: white;
		font-weight: 400;
		letter-spacing: 1px;
	}
}
