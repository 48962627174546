.container-about {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: 100vh;
	position: relative;
	background-color: var(--primary);
	overflow: hidden;
}

.leftSide {
	width: 70%;
	box-sizing: border-box;
	z-index: 1;
	padding-inline: 80px;
}

.rightSide {
	position: absolute;
	width: 60%;
	right: -200px;
	top: 0;
	margin-left: auto;
}

.picture {
	transform: rotateZ(70deg);
}

.text {
	color: white;
	font-size: 4vw;
	font-weight: 900;
	letter-spacing: 0.5px;
	text-align: left;
}

.description {
	font-size: 1.8vw;
	font-weight: 100;
	letter-spacing: 1px;
	font-style: italic;
	color: white;
	padding-right: 50px;
}

.text span {
	color: var(--secondary);
	text-shadow: none;
}

.rotating-text {
	animation: fadeRight 1.5s ease-out;
}

@keyframes fadeRight {
	from {
		opacity: 0;
		transform: translateX(-50px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes circle-in-bottom-left {
	from {
		clip-path: circle(0%);
	}
	to {
		clip-path: circle(150% at bottom left);
	}
}

@media (max-width: 768px) {
	.container-about {
		display: flex;
		flex-direction: column;
		min-height: 80vh;
		height: auto;
		position: relative;
	}
	.leftSide {
		width: 100%;
		padding-inline: 0px;
		padding-left: 0;
		padding-right: 0;
		height: auto;
		padding-inline: 20px;
	}

	.rightSide {
		position: relative;
		width: 100%;
		right: 0;
		top: 0;
		margin-left: 0;
	}

	.text {
		text-align: center;
		font-size: 8vw;
		text-wrap: nowrap;
	}

	.description {
		font-size: 4vw;
		font-weight: 300;
		text-align: center;
		padding-right: 0px;
	}
	.picture {
		transform: rotateZ(-180deg);
	}
}
